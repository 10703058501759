import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { PdfService } from '../../core/tools/pdf.service';
import { PedidoItensService } from '../../core/services/pedidos/pedido-itens.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';
import { PedidoStatusService } from '../../core/services/pedidos/pedido-status.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { Estatistica } from '../../core/models/pedidos/estatistica';
import { Pedido } from '../../core/models/pedidos/pedido';
import { PedidoItensSintetico } from '../../core/models/pedidos/pedido-itens-sintetico';
import { PedidoPagamento } from '../../core/models/pedidos/pedido-pagamento';
import { PedidoSintetico } from '../../core/models/pedidos/pedido-sintetico';
import { PedidoStatus } from '../../core/models/pedidos/pedido-status';

@Component({
  selector: 'app-pedidos-atencao',
  templateUrl: './pedidos-atencao.component.html',
  styleUrls: ['./pedidos-atencao.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class PedidosAtencaoComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Filtros */

  formFiltro: FormGroup;

  filtroOpcoes: any[] = [
    { texto: "Período (Enviado)", valor: "0" },
    { texto: "Cliente", valor: "1" },
    { texto: "Nro Pedido", valor: "2" },
    { texto: "Status", valor: "3" }
  ];

  enviado: boolean = false;
  filtrado: boolean = false;

  /* Análise */

  qtdePedidos: number = 0;
  qtdePositivacao: number = 0;
  valorVendas: number = 0;
  litragemTotal: number = 0;
  valorTicketMedio: number = 0;

  top5Clientes: number = 0;
  porcentagemTop5Clientes: number = 0;

  top10Clientes: number = 0;
  porcentagemTop10Clientes: number = 0;

  /* Estatísticas */

  estatisticas: Estatistica[] = [];

  /* Status */

  filtroStatus: any[] = [
    { texto: "Todos", valor: "Todos" },
    { texto: "À Integrar", valor: "À Integrar" },
    { texto: "Aguard Aprov", valor: "Aguard Aprov" },
    { texto: "Aguard Cad", valor: "Aguard Cad" },
    { texto: "Erro Integ", valor: "Erro Integ" },
    { texto: "Reprovado", valor: "Reprovado" }
  ];

  /* Configurações */

  @Input() configuracao: Configuracao;

  /* Fator Financeiro */

  exibirFatorFinanceiro: boolean = false;

  /* Ordernar (Pedidos) */

  ordernarPedidosOpcoes: any[] = [];

  ordernarPedidosOpcoesOrdem: any[] = [
    { texto: "Crescente", valor: "0" },
    { texto: "Decrescente", valor: "1" }
  ];

  ordernarPedidosSelecionado: string = "order_sent_on";
  ordernarPedidosOrdemSelecionado: string = "1";

  /* Pedidos */

  pedidos: PedidoSintetico[] = [];
  pedidosOriginal: PedidoSintetico[] = [];

  pedidoAprovado: boolean = false;
  pedidoDesativado: boolean = false;
  pedidoReintegrado: boolean = false;
  pedidoReprovado: boolean = false;
  pedidoRetornado: boolean = false;

  pedidosItensSaldo: number = 0;

  /* Pedidos Itens */

  pedidosItens: PedidoItensSintetico[] = [];
  pedidosItensOriginal: PedidoItensSintetico[] = [];

  itemExpandido: number = -1;

  /* Pedidos (Pagamentos) */

  pedidoPagamento: PedidoPagamento = null;

  /* Pedidos (Status) */

  pedidosStatus: PedidoStatus[] = [];

  /* Reprovar */

  motivoReprovacao: string = "";

  /* Storage */

  is_master: boolean = (localStorage.getItem("is_master") == "true");
  is_manager: boolean = (localStorage.getItem("is_manager") == "true");
  is_master_seller: boolean = (localStorage.getItem("is_master_seller") == "true");
  is_diretor: boolean = (localStorage.getItem("is_diretor") == "true");
  modelo_tela_ligacoes: number = parseInt(localStorage.getItem("modelo_tela_ligacoes"));

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private pedidoItensService: PedidoItensService,
    private pedidoService: PedidoService,
    private pedidoStatusService: PedidoStatusService,
    private pdfService: PdfService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    /* Filtros */

    this.formFiltro = this.formBuilder.group({
      filtro: ["0"],
      dt_inicial: ["", Validators.required],
      dt_final: ["", Validators.required],
      cliente: ["", Validators.required],
      nro_pedido: ["", Validators.required],
      status: ["Todos"],
      vendedor: [""],
      filtrar_b2b: [false]
    });

    this.organizarFiltro();

    /* Ordenar */

    if (this.modelo_tela_ligacoes == 0) {
      /* Modelo 1 */

      this.ordernarPedidosOpcoes = [
        { texto: "Status", valor: "status_pedido" },
        { texto: "Nro Pedido", valor: "order_id_app" },
        { texto: "Vend Externo", valor: "vend_ext" },
        { texto: "Vend Interno", valor: "vend_int" },
        { texto: "Cliente", valor: "name" },
        { texto: "CNPJ/CPF", valor: "client_document" },
        { texto: "Total", valor: "total" },
        { texto: "Litragem", valor: "total_litragem" },
        { texto: "Faturado", valor: "total_fat" },
        { texto: "Enviado", valor: "order_sent_on" },
        { texto: "Liberado", valor: "dt_liberacao_master" },
        { texto: "Integrado", valor: "dt_integracao" },
        { texto: "Prazo Pagto", valor: "prazo_pgto" },
        { texto: "Tipo", valor: "cod_tipopedvenda" },
        { texto: "Nota Fiscal", valor: "numero_nf" }
      ];

      this.ordernarPedidosOrganizarOpcoes();
    }

    this.carregarDados(true);
  }

  get f() {
    return this.formFiltro.controls;
  }

  /* Ações */

  alterar(id_pedido: number, seller_id: number) {
    let pedido: Pedido = null;

    this.pedidoService.getByOrder(id_pedido, seller_id).subscribe(
      pedidos => pedido = pedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (pedido.order_sent_on == null) {
          this.router.navigate(["/pedidos-cadastro/" + id_pedido], {
            queryParams: {
              vendedor: seller_id
            }
          });
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  aprovar(id_pedido: number, seller_id: number) {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(id_pedido, seller_id).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (statusPedido == "Aguard Aprov") {
          this.pedidoService.aprovar(id_pedido, seller_id).subscribe(
            pedidoAprovado => this.pedidoAprovado = pedidoAprovado,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.pedidoAprovado) {
                this.toastr.success("", "Pedido aprovado com sucesso!");
              } else {
                this.toastr.error("", "Erro ao realizar aprovação! Contate o suporte...");
              }
              this.carregarDados(false);
            }
          );
        } else {
          this.toastr.info("", "Tente novamente...");
          this.carregarDados(false);
        }
      }
    );
  }

  analisar(id_pedido: number, seller_id: number) {
    this.router.navigate(["/pedidos-cadastro/" + id_pedido], {
      queryParams: {
        aprovar: true,
        vendedor: seller_id
      }
    });
  }

  duplicar(id_pedido: number, seller_id: number) {
    this.router.navigate(["/pedidos-cadastro/" + id_pedido], {
      queryParams: {
        duplicar: true,
        vendedor: seller_id
      }
    });
  }

  integrar(id_pedido: number, seller_id: number) {
    /* Pedido Itens */

    let alterarPedido: boolean = false;

    this.pedidoService.updateDataIntegracaoByOrderIdApp(id_pedido, seller_id).subscribe(
      alterarPedidos => alterarPedido = alterarPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (alterarPedido) {
          this.toastr.success("", "Pedido integrado com sucesso!");
        } else {
          this.toastr.error("", "Erro ao realizar integração! Contate o suporte...");
        }

        this.carregarDados(false);
      }
    );
  }

  reintegrar(id_pedido: number, seller_id: number) {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(id_pedido, seller_id).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (statusPedido == "À Integrar" || statusPedido == "Erro Integ") {
          this.pedidoService.reintegrar(id_pedido, seller_id).subscribe(
            pedidoReintegrado => this.pedidoReintegrado = pedidoReintegrado,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.pedidoReintegrado) {
                this.toastr.success("", "Pedido reintegrado com sucesso!");
              } else {
                this.toastr.error("", "Erro ao realizar reintegração! Contate o suporte...");
              }

              this.carregarDados(false);
            }
          );
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  retornar(id_pedido: number, seller_id: number) {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(id_pedido, seller_id).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (statusPedido == "Erro Integ" || statusPedido == "Reprovado") {
          this.pedidoService.retornar(id_pedido, seller_id).subscribe(
            pedidoRetornado => this.pedidoRetornado = pedidoRetornado,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.pedidoRetornado) {
                this.toastr.success("", "Pedido retornado com sucesso!");
              } else {
                this.toastr.error("", "Erro ao realizar retorno! Contate o suporte...");
              }

              this.carregarDados(false);
            }
          );
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  /* Dados */

  calcularDados() {
    /* Pedidos */

    if (this.pedidos != null) {
      /* Análise */

      this.qtdePedidos = this.pedidos.length;

      let clientesAgrupados = this.pedidos.reduce((obj, item) => {
        obj[item.client_id] = obj[item.client_id] || [];
        obj[item.client_id].push(item);

        return obj;
      }, {});

      this.qtdePositivacao = Object.keys(clientesAgrupados).length;
      this.valorVendas = this.pedidos.reduce((sum, current) => sum + current.total, 0);
      this.litragemTotal = this.pedidos.reduce((sum, current) => sum + current.total_litragem, 0);
      this.valorTicketMedio = 0;

      if (this.configuracao != null && this.configuracao.pedido_exibir_litragem) {
        if (this.valorVendas > 0) {
          this.valorTicketMedio = this.litragemTotal / this.qtdePedidos;
        }
      } else {
        if (this.valorVendas > 0) {
          this.valorTicketMedio = this.valorVendas / this.qtdePedidos;
        }
      }

      this.top5Clientes = 0;
      this.porcentagemTop5Clientes = 0;

      this.top10Clientes = 0;
      this.porcentagemTop10Clientes = 0;

      let resultadoAgrupado = this.pedidos.reduce((obj, item) => {
        let pedidoAgrupado = item["client_id"];

        if (!obj[pedidoAgrupado]) {
          obj[pedidoAgrupado] = [];
        }

        obj[pedidoAgrupado].push(item);

        return obj;
      }, {});

      const pedidosAgrupados = [];

      for (let client_id in resultadoAgrupado) {
        let total = resultadoAgrupado[client_id].reduce((obj, item) => {
          return obj + item.total;
        }, 0);

        let total_litragem = resultadoAgrupado[client_id].reduce((obj, item) => {
          return obj + item.total_litragem;
        }, 0);

        pedidosAgrupados.push({
          cliente_id: client_id,
          name: resultadoAgrupado[client_id][0].name,
          total: total,
          total_litragem: total_litragem
        });
      };

      pedidosAgrupados.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["total"], b["total"]) * -1; /* Decrescente */
      });

      /* Top 5 */

      let qtdePedidosAgrupados = pedidosAgrupados.length < 5 ? pedidosAgrupados.length : 5;

      if (this.configuracao != null && this.configuracao.pedido_exibir_litragem) {
        for (let i = 0; i < qtdePedidosAgrupados; i++) {
          this.top5Clientes = this.top5Clientes + pedidosAgrupados[i].total_litragem;
        }

        if (this.top5Clientes > 0) {
          this.porcentagemTop5Clientes = ((this.top5Clientes / this.litragemTotal) * 100);
        }
      } else {
        for (let i = 0; i < qtdePedidosAgrupados; i++) {
          this.top5Clientes = this.top5Clientes + pedidosAgrupados[i].total;
        }

        if (this.top5Clientes > 0) {
          this.porcentagemTop5Clientes = ((this.top5Clientes / this.valorVendas) * 100);
        }
      }

      /* Top 10 */

      qtdePedidosAgrupados = pedidosAgrupados.length < 10 ? pedidosAgrupados.length : 10;

      if (this.configuracao != null && this.configuracao.pedido_exibir_litragem) {
        for (let i = 0; i < qtdePedidosAgrupados; i++) {
          this.top10Clientes = this.top10Clientes + pedidosAgrupados[i].total_litragem;
        }

        if (this.top10Clientes > 0) {
          this.porcentagemTop10Clientes = ((this.top10Clientes / this.litragemTotal) * 100);
        }
      } else {
        for (let i = 0; i < qtdePedidosAgrupados; i++) {
          this.top10Clientes = this.top10Clientes + pedidosAgrupados[i].total;
        }

        if (this.top10Clientes > 0) {
          this.porcentagemTop10Clientes = ((this.top10Clientes / this.valorVendas) * 100);
        }
      }

      /* Estatísticas */

      this.estatisticas = [];

      /* À Integrar */

      let valorAIntegrar = this.pedidos.filter(pedidos => pedidos.status_pedido == "À Integrar").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemAIntegrar = this.pedidos.filter(pedidos => pedidos.status_pedido == "À Integrar").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeAIntegrar = this.pedidos.filter(pedidos => pedidos.status_pedido == "À Integrar").length;
      let porcentagemAIntegrar = 0;

      if (valorAIntegrar > 0) {
        porcentagemAIntegrar = ((valorAIntegrar / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("a_integrar", "À Integrar", valorAIntegrar, valorLitragemAIntegrar, qtdeAIntegrar, porcentagemAIntegrar));

      /* Aguard Aprov */

      let valorAguardAprov = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Aprov").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemAguardAprov = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Aprov").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeAguardAprov = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Aprov").length;
      let porcentagemAguardAprov = 0;

      if (valorAguardAprov > 0) {
        porcentagemAguardAprov = ((valorAguardAprov / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("aguard_aprov", "Aguard Aprov", valorAguardAprov, valorLitragemAguardAprov, qtdeAguardAprov, porcentagemAguardAprov));

      /* Aguard Cad */

      let valorAguardCad = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Cad").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemAguardCad = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Cad").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeAguardCad = this.pedidos.filter(pedidos => pedidos.status_pedido == "Aguard Cad").length;
      let porcentagemAguardCad = 0;

      if (valorAguardCad > 0) {
        porcentagemAguardCad = ((valorAguardCad / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("aguard_cad", "Aguard Cad", valorAguardCad, valorLitragemAguardCad, qtdeAguardCad, porcentagemAguardCad));

      /* Erro Integ */

      let valorErroInteg = this.pedidos.filter(pedidos => pedidos.status_pedido == "Erro Integ").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemErroInteg = this.pedidos.filter(pedidos => pedidos.status_pedido == "Erro Integ").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeErroInteg = this.pedidos.filter(pedidos => pedidos.status_pedido == "Erro Integ").length;
      let porcentagemErroInteg = 0;

      if (valorErroInteg > 0) {
        porcentagemErroInteg = ((valorErroInteg / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("erro_integ", "Erro Integ", valorErroInteg, valorLitragemErroInteg, qtdeErroInteg, porcentagemErroInteg));

      /* Reprovado */

      let valorReprovado = this.pedidos.filter(pedidos => pedidos.status_pedido == "Reprovado").reduce((sum, current) => sum + current.total, 0);
      let valorLitragemReprovado = this.pedidos.filter(pedidos => pedidos.status_pedido == "Reprovado").reduce((sum, current) => sum + current.total_litragem, 0);
      let qtdeReprovado = this.pedidos.filter(pedidos => pedidos.status_pedido == "Reprovado").length;
      let porcentagemReprovado = 0;

      if (valorReprovado > 0) {
        porcentagemReprovado = ((valorReprovado / this.valorVendas) * 100);
      }

      this.estatisticas.push(new Estatistica("reprovado", "Reprovado", valorReprovado, valorLitragemReprovado, qtdeReprovado, porcentagemReprovado));

      this.estatisticas.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a["valor"], b["valor"]) * -1; /* Decrescente */
      });
    }
  }

  carregarDados(inicial: boolean) {
    this.carregando = true;

    this.itemExpandido = -1;

    /* Pedidos */

    this.pedidoService.getByUsuario(true).subscribe(
      pedidos => this.pedidos = pedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.pedidos.length > 0) {
          this.pedidos.forEach((item) => {
            switch (item.status_pedido) {
              case "À Integrar":
                item.ordem = 2;

                break;
              case "Aguard Aprov":
                item.ordem = 0;

                break;
              case "Aguard Cad":
                item.ordem = 1;

                break;
              case "Erro Integ":
                item.ordem = 4;

                break;
              case "Reprovado":
                item.ordem = 3;

                break;
              default:
                item.ordem = 0;

                break;
            }
          });

          this.pedidos = this.pedidos.sort((a, b) => {
            const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

            return compare(a["ordem"], b["ordem"]);
          });
        }

        this.pedidosOriginal = JSON.parse(JSON.stringify(this.pedidos));

        this.calcularDados();

        if (!inicial) {
          this.filtrar(false);
        }
      }
    );
  }

  reiniciarDados() {
    /* Pedidos */

    this.pedidos = JSON.parse(JSON.stringify(this.pedidosOriginal));
  }

  /* Expandir */

  expandir(i: number, id: number, seller_id: number) {
    this.exibirFatorFinanceiro = false;

    this.itemExpandido = i == this.itemExpandido ? -1 : i;

    this.pedidosItensSaldo = 0;

    if (this.itemExpandido > -1) {
      this.pedidoItensService.get15DiasByOrder(id, seller_id).subscribe(
        pedidosItens => this.pedidosItens = pedidosItens,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.pedidosItens.length > 0) {
            this.pedidosItensSaldo = this.pedidosItens.reduce((sum, current) => sum + current.valor_desconto, 0);

            this.pedidosItensOriginal = JSON.parse(JSON.stringify(this.pedidosItens));

            let qtdeProdutosFatorFinanceiro: number = this.pedidosItens.filter(pedidoItem => pedidoItem.fator_financeiro != null && pedidoItem.fator_financeiro != 0).length;

            if (qtdeProdutosFatorFinanceiro > 0) {
              this.exibirFatorFinanceiro = true;
            }
          } else {
            this.pedidosItensOriginal = [];
          }
        }
      );
    }
  }

  /* Filtros */

  alterarFiltro(filtro_selecionado: string) {
    let filtroAtual: string = this.formFiltro.controls["filtro"].value;
    let filtroB2BAtual: boolean = this.formFiltro.controls["filtrar_b2b"].value;

    this.formFiltro.reset();

    if (filtro_selecionado == null) {
      this.formFiltro.controls["filtro"].setValue(filtroAtual);
      this.formFiltro.controls["filtrar_b2b"].setValue(false);

      this.calcularDados();
    } else {
      this.formFiltro.controls["filtro"].setValue(filtro_selecionado);
      this.formFiltro.controls["filtrar_b2b"].setValue(filtroB2BAtual);
    }

    this.formFiltro.controls["status"].setValue("Todos");

    this.organizarFiltro();

    this.enviado = false;
    this.filtrado = false;

    this.reiniciarDados();
    this.calcularDados();
  }

  filtrar(formulario: boolean) {
    this.filtrado = false;

    this.reiniciarDados();

    if (this.formFiltro.controls["filtrar_b2b"].value) {
      this.pedidos = this.pedidos.filter(pedido => pedido.order_id_app.toString().substring(0, 3) == "130");

      this.filtrado = true;
    }

    if (formulario) {
      this.enviado = true;

      if (this.formFiltro.invalid) {
        return;
      }
    } else {
      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Período (Enviado) */

        if (this.formFiltro.controls["dt_inicial"].value == "" || this.formFiltro.controls["dt_inicial"].value == null || this.formFiltro.controls["dt_final"].value == "" || this.formFiltro.controls["dt_final"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        if (this.formFiltro.controls["cliente"].value == "" || this.formFiltro.controls["cliente"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* Nro Pedido */

        if (this.formFiltro.controls["nro_pedido"].value == "" || this.formFiltro.controls["nro_pedido"].value == null) {
          return;
        }
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Status */

        if (this.formFiltro.controls["status"].value == "" || this.formFiltro.controls["status"].value == null) {
          return;
        }
      }
    }

    this.filtrado = true;

    /* Pedidos */

    if (this.pedidos != null) {
      if (this.formFiltro.controls["filtro"].value == "0") {
        /* Período (Enviado) */

        /* Dt. Inicial */

        let dataInicial = this.formFiltro.controls["dt_inicial"].value;

        dataInicial = new Date(dataInicial.year, dataInicial.month - 1, dataInicial.day);
        dataInicial = this.datePipe.transform(dataInicial, "yyyy/MM/dd");

        /* Dt. Final */

        let dataFinal = this.formFiltro.controls["dt_final"].value;

        dataFinal = new Date(dataFinal.year, dataFinal.month - 1, dataFinal.day);
        dataFinal = this.datePipe.transform(dataFinal, "yyyy/MM/dd");

        this.pedidos = this.pedidos.filter(pedido => {
          let data = this.datePipe.transform(pedido.order_sent_on, "yyyy/MM/dd");

          return data >= dataInicial && data <= dataFinal;
        });
      } else if (this.formFiltro.controls["filtro"].value == "1") {
        /* Cliente */

        this.pedidos = this.pedidos.filter(pedido => {
          return (pedido.name.toLowerCase().indexOf(this.formFiltro.controls["cliente"].value.trim().toLowerCase()) !== -1);
        });
      } else if (this.formFiltro.controls["filtro"].value == "2") {
        /* Nro Pedido */

        this.pedidos = this.pedidos.filter(pedido => pedido.order_id_app == this.formFiltro.controls["nro_pedido"].value);
      } else if (this.formFiltro.controls["filtro"].value == "3") {
        /* Status */

        if (this.formFiltro.controls["status"].value != "Todos") {
          this.pedidos = this.pedidos.filter(pedido => pedido.status_pedido == this.formFiltro.controls["status"].value);
        }
      }

      if (this.formFiltro.controls["vendedor"].value != "" && this.formFiltro.controls["vendedor"].value != null) {
        /* Vendedor(a) */

        this.pedidos = this.pedidos.filter(pedido => {
          return (pedido.vend_ext.toLowerCase().indexOf(this.formFiltro.controls["vendedor"].value.trim().toLowerCase()) !== -1);
        });
      }
    }

    this.calcularDados();
  }

  filtrarB2B() {
    this.filtrar(false);
  }

  organizarFiltro() {
    if (this.formFiltro.controls["filtro"].value == "0") {
      /* Período (Enviado) */

      this.formFiltro.get("dt_inicial").enable();
      this.formFiltro.get("dt_final").enable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "1") {
      /* Cliente */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").enable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "2") {
      /* Nro Pedido */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").enable();
      this.formFiltro.get("status").disable();
    } else if (this.formFiltro.controls["filtro"].value == "3") {
      /* Status */

      this.formFiltro.get("dt_inicial").disable();
      this.formFiltro.get("dt_final").disable();
      this.formFiltro.get("cliente").disable();
      this.formFiltro.get("nro_pedido").disable();
      this.formFiltro.get("status").enable();
    }
  }

  /* Formatação */

  formatarValoresQuatroCasasDecimais(valor: number) {
    if (valor != null) {
      return (valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });
    }
  }

  /* Imprimir */

  imprimir(id_pedido: number, seller_id: number) {
    this.pdfService.imprimirPedidoOrcamento(id_pedido, seller_id, 0);
  }

  /* Modal */

  modalConsultarPagamento(content: any, id: number) {
    /* Pedidos (Pagamentos) */

    this.pedidoService.getPedidoPagamentoById(id).subscribe(
      pedidoPagamento => this.pedidoPagamento = pedidoPagamento,
      error => { console.log("Erro: " + error) },
      () => {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-consultar-pagamento",
          size: "lg",
          backdrop: "static"
        });
      }
    );
  }

  modalPedidoReprovar(content: any, id_pedido: number, seller_id: number) {
    let statusPedido: string;

    this.pedidoService.getStatusPedidoByOrder(id_pedido, seller_id).subscribe(
      statusPedidos => statusPedido = statusPedidos,
      error => { console.log("Erro: " + error) },
      () => {
        if (statusPedido == "Aguard Aprov") {
          this.motivoReprovacao = "";

          this.modalService.open(content, {
            ariaLabelledBy: "titulo-pedido-reprovar",
            size: "lg",
            backdrop: "static"
          }).result.then((acao) => {
            if (acao == "Salvar") {
              this.pedidoService.reprovar(id_pedido, seller_id, this.motivoReprovacao).subscribe(
                pedidoReprovado => this.pedidoReprovado = pedidoReprovado,
                error => { console.log("Erro: " + error) },
                () => {
                  if (this.pedidoReprovado) {
                    this.toastr.success("", "Pedido reprovado com sucesso!");
                  } else {
                    this.toastr.error("", "Erro ao realizar reprovação! Contate o suporte...");
                  }

                  this.carregarDados(false);
                }
              );
            }
          }, () => {

          });
        } else {
          this.toastr.info("", "Tente novamente...");

          this.carregarDados(false);
        }
      }
    );
  }

  modalPedidoStatus(content: any, id_pedido: number) {
    /* Pedidos (Status) */

    this.pedidoStatusService.getPedidoStatusByOrder(id_pedido).subscribe(
      pedidosStatus => this.pedidosStatus = pedidosStatus,
      error => { console.log("Erro: " + error) },
      () => {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-pedido-status",
          size: "lg",
          backdrop: "static"
        });
      }
    );
  }

  /* Ordenar (Pedidos) */

  ordernarPedidos(opcao_selecionada: string) {
    this.ordernarPedidosSelecionado = opcao_selecionada;

    switch (this.ordernarPedidosOrdemSelecionado) {
      case "0":
        /* Crescente */

        this.pedidosOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarPedidosSelecionado], b[this.ordernarPedidosSelecionado]);
        });

        break;
      case "1":
        /* Decrescente */

        this.pedidosOriginal.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a[this.ordernarPedidosSelecionado], b[this.ordernarPedidosSelecionado]) * -1; /* Decrescente */
        });

        break;
      default:

    }

    this.pedidos = JSON.parse(JSON.stringify(this.pedidosOriginal));

    if (this.filtrado) {
      this.filtrar(false);
    }
  }

  ordernarPedidosOrdem(opcao_selecionada: string) {
    this.ordernarPedidosOrdemSelecionado = opcao_selecionada;

    this.ordernarPedidos(this.ordernarPedidosSelecionado);
  }

  ordernarPedidosOrganizarOpcoes() {
    this.ordernarPedidosOpcoes.sort((a, b) => {
      const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

      return compare(a["texto"], b["texto"]);
    });
  }
}
