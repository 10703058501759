import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConversaoService } from '../../core/tools/conversao.service';
import { PdfService } from '../../core/tools/pdf.service';
import { RotaService } from '../../core/services/rotas/rota.service';
import { ValidacaoFormularioService } from '../../core/tools/validacao-formulario.service';
import { VendedorService } from '../../core/services/vendedores/vendedor.service';

/* Models */

import { RotaConfiguracao } from '../../core/models/rotas/rota-configuracao';
import { RotaSintetica } from '../../core/models/rotas/rota-sintetica';
import { Vendedor } from '../../core/models/vendedores/vendedor';

/* Maps */

declare var google: any;

import { } from '@google/maps';

import { InfoWindow } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'app-rotas',
  templateUrl: './rotas.component.html',
  styleUrls: ['./rotas.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class RotasComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;
  processando: number = 0;

  /* Alteração (Rota) */

  formAlterarRota: FormGroup;

  enviadoAlterarRota: boolean = false;

  /* Campos */

  campoDiaVisita: { year: number, month: number, day: number } = null;

  /* Chamadas */

  qtdeChamadas: number = 0;

  /* Drag and Drop */

  exibirDragAndDrop: boolean = true;

  erroDragAndDrop: boolean = false;

  /* Maps */

  iconeCor: number = 0;

  iconeCores = [
    "#aa0000", "#0088aa", "#00aa00", "#4400aa", "#aa8800",
    "#a02c2c", "#2c89a0", "#2ca02c", "#5a2ca0", "#a0892c",
    "#aa4400", "#0044aa", "#00aa44", "#8800aa", "#88aa00",
    "#a05a2c", "#2c5aa0", "#2ca05a", "#892ca0", "#89a02c",
    "#917c6f", "#0000aa", "#00aa88", "#aa0088", "#44aa00",
    "#916f7c", "#2c2ca0", "#2ca089", "#aa0044", "#5aa02c"
  ];

  iconeOrigem: string = "assets/pins/";

  infoWindowDirection: InfoWindow = undefined;

  latitudeFoco: number = -22.8657664;
  longitudeFoco: number = -47.0263092;

  mapaRotas = [];
  mapaRotasContador: number = 0;

  mapaRotasRetorno = [];

  mapaClienteComRota = [];
  mapaClienteComRotaSelecionado: number = -1;
  mapaClienteComRotaPontoSelecionado: number = -1;

  mapaClienteSemRota = [];
  mapaClienteSemRotaPontoSelecionado: number = -1;

  visibilidadeInicial: boolean = false;

  zoom: number = 8;

  /* Duplicar (Cliente) */

  duplicarRotaSelecionada: RotaSintetica = null;

  /* Nova (Rota) */

  enviadoNovaRota: boolean = false;

  formNovaRota: FormGroup;

  novaRotaDefinindoDestino: boolean = false;
  novaRotaDefinindoOrigem: boolean = false;
  novaRotaDestino: boolean = true;
  novaRotaExibir: boolean = true;
  novaRotaOrigem: boolean = true;
  novaRotaProcessando: boolean = false;
  novaRotaTotalObjetivo: number = 0;

  novaRotaDados: RotaSintetica = null;

  novaRotaAgrupada: RotaSintetica[] = [];

  /* Reposicionar (Cliente) */

  enviadoReposicionarClienteManual: boolean = false;

  formReposicionarClienteManual: FormGroup;

  reposicionarRotaSelecionada: RotaSintetica = null;

  /* Rotas (Configuração) */

  rotasConfiguracao: RotaConfiguracao = null;

  /* Rotas (Sintética) */

  clientesSemRota = "Clientes Sem Rota";

  rotas: RotaSintetica[] = [];

  rotasAgrupadas = null;

  rotasAgrupadasOriginal = null;

  rotasNova: boolean = false;
  rotasSalvar: boolean = false;

  rotaOrigem: string = "";

  rotaSelecionada: number = 0;

  rotaPosicaoSelecionada: number = 0;

  rotaSelecionadaPosicoes: number[] = [];

  /* Vendedores */

  vendedores: Vendedor[] = [];

  vendedorSelecionado: Vendedor = null;

  /* Modal */

  @ViewChild("excluirRota", { static: false }) excluirRota: ElementRef;
  @ViewChild("iniciarRotas", { static: false }) iniciarRotas: ElementRef;
  @ViewChild("reposicionarClienteManual", { static: false }) reposicionarClienteManual: ElementRef;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private clienteService: ClienteService,
    private conversaoService: ConversaoService,
    private pdfService: PdfService,
    private rotaService: RotaService,
    private validacaoFormularioService: ValidacaoFormularioService,
    private vendedorService: VendedorService
  ) { }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.formAlterarRota = this.formBuilder.group({
      rota_nome: ["", Validators.required],
      rota_dia_visita: [this.campoDiaVisita, this.validacaoFormularioService.validarData]
    });

    this.formNovaRota = this.formBuilder.group({
      rota_nome: ["", Validators.required],
      rota_dia_visita: [this.campoDiaVisita, this.validacaoFormularioService.validarData],

      /* Origem */

      rota_origem_logradouro: ["", Validators.required],
      rota_origem_endereco: ["", Validators.required],
      rota_origem_numero: ["", Validators.required],
      rota_origem_bairro: ["", Validators.required],
      rota_origem_cidade: ["", Validators.required],

      /* Destino */

      rota_destino_logradouro: ["", Validators.required],
      rota_destino_endereco: ["", Validators.required],
      rota_destino_numero: ["", Validators.required],
      rota_destino_bairro: ["", Validators.required],
      rota_destino_cidade: ["", Validators.required]
    });

    this.formReposicionarClienteManual = this.formBuilder.group({
      latitude: ["", [Validators.required, this.validacaoFormularioService.validarSomenteNumeros]],
      longitude: ["", [Validators.required, this.validacaoFormularioService.validarSomenteNumeros]],
    });

    this.carregarDados(true);
  }

  get far() {
    return this.formAlterarRota.controls;
  }

  get fnr() {
    return this.formNovaRota.controls;
  }

  get frc() {
    return this.formReposicionarClienteManual.controls;
  }

  /* Ação */

  abrirMapaDestino() {
    this.novaRotaDefinindoDestino = true;
    this.novaRotaDefinindoOrigem = false;

    this.modalService.dismissAll();

    this.toastr.info("", "Clique no endereço de destino...");
  }

  abrirMapaOrigem() {
    this.novaRotaDefinindoDestino = false;
    this.novaRotaDefinindoOrigem = true;

    this.modalService.dismissAll();

    this.toastr.info("", "Clique no endereço de origem...");
  }

  desativarPonto(id: string, route_id_app: number, rota_origem: number, posicao_atual: number) {
    if (this.rotasAgrupadas[rota_origem] != null && this.rotasAgrupadas[rota_origem].value[posicao_atual] != null) {
      let desativarPonto: boolean = false;  

      let rotaOrigem: RotaSintetica = this.rotasAgrupadas[rota_origem].value[posicao_atual];

      if (!rotaOrigem.duplicado) {
        this.rotaService.updateIsActiveById(id, route_id_app, false).subscribe(
          desativarPontos => desativarPonto = desativarPontos,
          error => { console.log("Erro: " + error) },
          () => {
            if (desativarPonto) {
              /* Atualização */

              if (rotaOrigem.frequencia_qtde_atual < 2) {
                let rotaDestino: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == this.clientesSemRota);

                this.expandirRecolherRota(rotaDestino, true);

                this.toastr.success("", "Enviado p/ '" + this.clientesSemRota + "'!");
              } else {
                this.toastr.success("", "Cliente desativado da rota com sucesso!");
              }
            }
          }
        );
      } else {
        this.toastr.success("", "Cliente desativado da rota com sucesso!");
      }

      this.atualizarRotas();
    }
  }

  expandirRecolherNovaRota() {
    this.novaRotaExibir = !this.novaRotaExibir;
  }

  expandirRecolherRota(rota: number, exibir?: boolean) {
    if (this.qtdeChamadas > 0) {
      if (this.rotasAgrupadas[rota] != null) {
        if (this.mapaRotas[rota] != null) {
          /* Ação */

          this.mapaRotas[rota].visible = exibir == undefined ? !this.mapaRotas[rota].visible : exibir;

          this.rotasAgrupadas[rota].exibir = exibir == undefined ? !this.rotasAgrupadas[rota].exibir : exibir;
          this.rotasAgrupadas[rota].distancia_rota = "0 m";

          if (this.rotasAgrupadas[rota].exibir) {
            this.atualizarChamadas();
          }

          if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
            /* S/ Rota */

            if (!this.mapaRotas[rota].visible) {
              this.mapaClienteSemRotaPontoSelecionado = -1;
            }
          } else {
            if (this.rotasAgrupadas[rota].exibir) {
              this.mapaClienteComRota.push(this.mapaRotas[rota]);
            } else {
              let index: number = this.mapaClienteComRota.findIndex(mapaCliente => mapaCliente.name == this.rotasAgrupadas[rota].key);

              if (index != -1) {
                this.mapaClienteComRota.splice(index, 1);
              }

              if (!this.mapaRotas[rota].visible) {
                this.mapaClienteComRotaSelecionado = -1;
                this.mapaClienteComRotaPontoSelecionado = -1;
              }
            }
          }
        } else {
          /* Ação */

          this.rotasAgrupadas[rota].exibir = exibir == undefined ? !this.rotasAgrupadas[rota].exibir : exibir;
        }
      }
    } else {
      this.toastr.error("", "Visualização indisponível...");
    }
  }

  imprimirRotas() {
    if (this.rotasAgrupadas != null) {
      this.pdfService.imprimirRotas(this.rotasAgrupadas);
    }
  }

  salvarNovaRota(otimizar: boolean) {
    if (this.novaRotaAgrupada.length > 0) {
      let qtdeNovaRotaAgrupada = this.novaRotaAgrupada.filter(novaRotaAgrupada => novaRotaAgrupada.name != "ORIGEM" && novaRotaAgrupada.name != "DESTINO").length;

      if (qtdeNovaRotaAgrupada > 0) {
        this.rotasAgrupadas[0].alterada = true;
        this.rotasAgrupadas[0].value = this.novaRotaAgrupada;

        this.definirRota(0, false, otimizar);

        this.fecharClientesSemRota();
      } else {
        this.toastr.error("", "Nenhum cliente adicionado!");
      }
    }
  }

  salvarRotas() {
    let rotasAlteradas: any = this.rotasAgrupadas.filter(rotaAgrupada => rotaAgrupada.alterada);

    if (rotasAlteradas.length > 0) {
      rotasAlteradas.forEach((item) => {
        if (item.nova) {
          if (item.dia_visita != null) {
            item.dia_visita = new Date(this.datePipe.transform(item.dia_visita, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
          }
        }
      });

      let alterarRotas: boolean = false;

      this.rotaService.updateRotaByRouteId(rotasAlteradas).subscribe(
        alterarRota => alterarRotas = alterarRota,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarRotas) {
            this.toastr.success("", "Rota(s) salva(s) com sucesso!");

            this.atualizarRotas();

            this.exibirDragAndDrop = true;

            this.pdfService.imprimirRotas(this.rotasAgrupadas);
          }
        }
      );
    }
  }

  /* Dados */

  agruparDados() {
    if (this.rotas.length > 0) {
      this.rotasAgrupadas = this.rotas.reduce((obj, item) => {
        obj[item.nome_rota] = obj[item.nome_rota] || [];
        obj[item.nome_rota].push(item);

        return obj;
      }, {});

      this.rotasAgrupadas = Object.keys(this.rotasAgrupadas).map((key) => {
        let totalObjetivoDeVenda = this.rotasAgrupadas[key].reduce((sum, current) => sum + current.objetivo_de_venda, 0);

        return {
          key: key,
          value: this.rotasAgrupadas[key],
          route_id_app: this.rotasAgrupadas[key][0] != null ? this.rotasAgrupadas[key][0].route_id_app : 0,
          alterada: false,
          exibir: this.visibilidadeInicial,
          visualizar: true,
          nova: false,
          cor: "#000",
          icone: this.retornarIconeCor(),
          distancia_rota: this.rotasAgrupadas[key][0] != null ? this.rotasAgrupadas[key][0].distancia_rota : "0 m",
          total_objetivo_de_venda: totalObjetivoDeVenda,
          dia_visita: this.rotasAgrupadas[key][0].dia_visita
        };
      });

      this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key != this.clientesSemRota);

      if (this.rotaSelecionada != -1) {
        this.definirPosicoesRotaDestino(this.rotasAgrupadas[this.rotaSelecionada].key);
      }

      this.atualizarFrequencia();

      this.processarRotas(true);
    }
  }

  atualizarChamadas() {
    this.vendedorService.updateRotasLimiteUsoByVendedor(this.vendedorSelecionado.id).subscribe(
      qtdeChamada => this.qtdeChamadas = qtdeChamada,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  atualizarFrequencia() {
    this.rotasAgrupadas.forEach((item) => {
      item.value.forEach((subItem) => {
        subItem.frequencia_qtde_atual = this.localizarQtdeFrequenciaCliente(subItem.client_id);
      });
    });
  }

  atualizarDistancia(rota: number) {
    let alterarDistancia: boolean = false;

    if (this.rotasAgrupadas[rota].key != this.clientesSemRota && this.rotasAgrupadas[rota].nova == false) {
      this.rotaService.updateRotaDistanciaById(this.rotasAgrupadas[rota]).subscribe(
        alterarDistancias => alterarDistancia = alterarDistancias,
        error => { console.log("Erro: " + error) },
        () => {
          if (alterarDistancia) {

          }
        }
      );
    }
  }

  carregarDados(iniciando: boolean) {
    this.carregando = true;

    /* Vendedores */

    this.vendedorService.getByUsuario().subscribe(
      vendedores => this.vendedores = vendedores,
      error => { console.log("Erro: " + error) },
      () => {
        if (iniciando) {
          if (this.vendedores.length > 0) {
            if (this.vendedorSelecionado != null) {
              this.carregarRotas();
            } else {
              this.vendedorSelecionado = this.vendedores[0];

              this.modalIniciarRotas(this.iniciarRotas, false);
            }
          }
        } else {
          this.modalIniciarRotas(this.iniciarRotas, true);
        }
      }
    );
  }

  carregarRotas() {
    /* Rotas (Configurações) */

    this.rotaService.getConfiguracaoByGuidClienteNapis().subscribe(
      rotasConfiguracao => this.rotasConfiguracao = rotasConfiguracao,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.rotasConfiguracao != null) {
          /* Rotas */

          this.rotaService.getByVendedor(this.vendedorSelecionado.id).subscribe(
            rotas => this.rotas = rotas,
            error => { console.log("Erro: " + error) },
            () => {
              if (this.rotas.length > 0) {
                let rota: string = "";
                let rota_item: number = 0;

                for (var i = 0; i < this.rotas.length; i++) {
                  if (rota != this.rotas[i].nome_rota) {
                    rota = this.rotas[i].nome_rota;

                    rota_item = 1;
                  }

                  this.rotas[i].route_id_app = this.rotas[i].route_id_app == null ? 0 : this.rotas[i].route_id_app;

                  this.rotas[i].cidade = this.rotas[i].cidade.trim();
                  this.rotas[i].endereco_completo = this.gerarEnderecoCompleto(i);

                  this.rotas[i].order_in_list = rota_item;
                  this.rotas[i].objetivo_de_venda = this.rotas[i].objetivo_de_venda == null ? 0 : this.rotas[i].objetivo_de_venda;

                  /* Drag and Drop */

                  this.rotas[i].effectAllowed = "move";
                  this.rotas[i].disable = false;
                  this.rotas[i].handle = false;

                  /* Frequência */

                  this.rotas[i].frequencia_qtde_atual = 0;

                  if (this.rotas[i].nome_rota == this.clientesSemRota) {
                    /* S/ Rota */

                    if (this.rotas[i].visita_frequencia == null) {
                      this.rotas[i].visita_frequencia = 0;
                    }
                  } else {
                    if (this.rotas[i].visita_frequencia == null) {
                      this.rotas[i].visita_frequencia = 1;
                    }
                  }

                  /* Maps */

                  this.rotas[i].distancia_rota = this.rotas[i].distancia_rota == null ? "0 m" : this.rotas[i].distancia_rota;
                  this.rotas[i].distancia = this.rotas[i].distancia == null ? "0 m" : this.rotas[i].distancia;

                  if (this.rotas[i].oficial_gps_lat == 0 || this.rotas[i].oficial_gps_lon == 0 || this.rotas[i].oficial_gps_lat == -1 || this.rotas[i].oficial_gps_lon == -1) {
                    this.rotas[i].reposicionar = true;
                  } else {
                    this.rotas[i].reposicionar = false;
                  }

                  rota_item++;
                }
              }

              this.atualizarChamadas();

              this.agruparDados();
            }
          )
        } else {
          this.toastr.error("", "Nenhuma configuração encontrada!");

          this.router.navigate(["/pedidos"]);
        }
      }
    );
  }

  gerarEnderecoCompleto(rota: number) {
    let endereco = "";

    if (this.rotas[rota].tipo_logradouro != null) {
      if (this.rotas[rota].tipo_logradouro.toUpperCase().trim() == "OUTROS") {
        this.rotas[rota].tipo_logradouro = "RUA";
      } else {
        this.rotas[rota].tipo_logradouro = this.rotas[rota].tipo_logradouro.toUpperCase().trim();
      }
    } else {
      this.rotas[rota].tipo_logradouro = "";
    }

    endereco = this.rotas[rota].tipo_logradouro + " " + this.rotas[rota].endereco + " " + this.rotas[rota].end_numero + ", " + this.rotas[rota].cidade + " - " + this.rotas[rota].estado;

    return endereco;
  }

  localizarQtdeFrequenciaCliente(client_id: number): number {
    let qtdeFrequencia: number = 0;

    this.rotasAgrupadas.forEach((item) => {
      item.value.forEach((subItem) => {
        if (subItem.client_id == client_id) {
          qtdeFrequencia++;
        }
      });
    });

    return qtdeFrequencia;
  }

  /* Drag and Drop */

  onDragStart(evento_drag: DragEvent) {
    
  }

  onDragEnd(evento_drag: DragEvent) {

  }

  onDragged(item: RotaSintetica, rotas: RotaSintetica[], effect: DropEffect) {
    if (this.erroDragAndDrop) {
      this.erroDragAndDrop = false;

      return;
    }

    if (effect == "move") {
      const index = rotas.indexOf(item);

      rotas.splice(index, 1);

      this.atualizarOrigem(this.rotaOrigem);
    }
  }

  onDrop(evento: DndDropEvent, route_id_app: number, rota_destino: string, rotas: RotaSintetica[]) {
    if (rotas != null && (evento.dropEffect == "copy" || evento.dropEffect == "move")) {
      this.rotaOrigem = evento.data.nome_rota;

      if (this.rotaOrigem != rota_destino) {
        /* Validar */

        if (!this.validarOnDrop(evento, route_id_app)) {
          if (evento.event != null) {
            evento.event.stopPropagation();
          }

          this.erroDragAndDrop = true;

          return;
        }
      }

      let index: number = evento.index;

      if (index == undefined) {
        index = rotas.length;
      }

      evento.data.nome_rota = rota_destino;
      evento.data.route_id_app_novo = route_id_app;

      if (evento.dropEffect == "copy") {
        evento.data.duplicado = true;
      }

      rotas.splice(index, 0, evento.data);

      if (this.rotaOrigem != rota_destino) {
        this.atualizarDestino(rota_destino);
      }
    }
  }

  validarOnDrop(evento: DndDropEvent, route_id_app: number): boolean {
    let rotaDestino: RotaSintetica[] = this.rotasAgrupadas.find(rotaAgrupada => rotaAgrupada.route_id_app == route_id_app).value;

    let cliente: RotaSintetica = rotaDestino.find(rotaDestino => rotaDestino.client_id == evento.data.client_id);

    if (cliente != null) {
      this.toastr.error("", "Cliente já existente na rota de destino...");

      return false;
    }

    return true;
  }

  /* Maps */

  buscarDestino(por_endereco: boolean, latitude?: number, longitude?: number, content?: any) {
    let retorno = new Promise<void>((resolver) => {
      let geolocalizacao = new google.maps.Geocoder();

      if (por_endereco) {
        /* Por Endereço */

        let endereco_destino: string = this.formNovaRota.controls["rota_destino_logradouro"].value + " " + this.formNovaRota.controls["rota_destino_endereco"].value + ", " + this.formNovaRota.controls["rota_destino_numero"].value + " - " + this.formNovaRota.controls["rota_destino_bairro"].value + " - " + this.formNovaRota.controls["rota_destino_cidade"].value;

        geolocalizacao.geocode({
          "address": endereco_destino
        }, (results, status) => {
          this.atualizarChamadas();

          if (status == google.maps.GeocoderStatus.OK) {
            let latitude: number = results[0].geometry.location.lat();
            let longitude: number = results[0].geometry.location.lng();

            let destino = new RotaSintetica();

            destino.nome_rota = String(this.formNovaRota.controls["rota_nome"].value).toLocaleUpperCase();
            destino.name = "DESTINO";
            destino.tipo_logradouro = String(this.formNovaRota.controls["rota_destino_logradouro"].value).toLocaleUpperCase();
            destino.endereco = String(this.formNovaRota.controls["rota_destino_endereco"].value).toLocaleUpperCase();
            destino.end_numero = String(this.formNovaRota.controls["rota_destino_numero"].value).toLocaleUpperCase();
            destino.bairro = String(this.formNovaRota.controls["rota_destino_bairro"].value).toLocaleUpperCase();
            destino.cidade = String(this.formNovaRota.controls["rota_destino_cidade"].value).toLocaleUpperCase();
            destino.oficial_gps_lat = latitude;
            destino.oficial_gps_lon = longitude;
            destino.objetivo_de_venda = 0;

            this.novaRotaAgrupada.push(destino);
          } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
            this.toastr.error("", "Endereço de destino inválido!");

            this.novaRotaDestino = false;
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");

            this.novaRotaDestino = false;
          }

          return resolver();
        });
      } else {
        /* Por Latitude/Longitude */

        let latitudeLongitude = {
          lat: latitude,
          lng: longitude,
        };

        geolocalizacao.geocode({
          "location": latitudeLongitude
        }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              let logradouro: string = "RUA";

              if (results[0].address_components[1].long_name.toUpperCase().indexOf("AVENIDA") !== -1) {
                logradouro = "AVENIDA"
              } else if (results[0].address_components[1].long_name.toUpperCase().indexOf("RODOVIA") !== -1) {
                logradouro = "RODOVIA"
              }

              let endereco: string = results[0].address_components[1].long_name.toUpperCase().replace(logradouro, "").trim();

              let numero: string = results[0].address_components[0].short_name;

              if (numero.toUpperCase().indexOf("UNNAMED ROAD") !== -1) {
                numero = "0";
              }

              let bairro: string = results[0].address_components[2].long_name.toUpperCase();

              let cidade: string = "";

              if (results[0].address_components.length > 3) {
                cidade = results[0].address_components[3].long_name.toUpperCase();
              } else {
                cidade = results[0].address_components[0].long_name.toUpperCase();
              }

              this.formNovaRota.controls["rota_destino_logradouro"].setValue(logradouro);
              this.formNovaRota.controls["rota_destino_endereco"].setValue(endereco);
              this.formNovaRota.controls["rota_destino_numero"].setValue(numero);
              this.formNovaRota.controls["rota_destino_bairro"].setValue(bairro);
              this.formNovaRota.controls["rota_destino_cidade"].setValue(cidade);

              this.modalNovaRota(content);

              this.toastr.success("", "Destino selecionado!");
            }
          }
        });
      }
    });

    return retorno;
  }

  buscarEnderecos() {
    let retorno = new Promise<void>((resolver) => {
      const buscarOrigem = this.buscarOrigem(true);

      buscarOrigem.then(() => {
        const buscarDestino = this.buscarDestino(true);

        buscarDestino.then(() => {
          return resolver();
        });
      });
    });

    return retorno;
  }

  buscarOrigem(por_endereco: boolean, latitude?: number, longitude?: number, content?: any) {
    let retorno = new Promise<void>((resolver) => {
      let geolocalizacao = new google.maps.Geocoder();

      if (por_endereco) {
        /* Por Endereço */

        let endereco_origem: string = this.formNovaRota.controls["rota_origem_logradouro"].value + " " + this.formNovaRota.controls["rota_origem_endereco"].value + ", " + this.formNovaRota.controls["rota_origem_numero"].value + " - " + this.formNovaRota.controls["rota_origem_bairro"].value + " - " + this.formNovaRota.controls["rota_origem_cidade"].value;

        geolocalizacao.geocode({
          "address": endereco_origem
        }, (results, status) => {
          this.atualizarChamadas();

          if (status == google.maps.GeocoderStatus.OK) {
            let latitude: number = results[0].geometry.location.lat();
            let longitude: number = results[0].geometry.location.lng();

            let origem = new RotaSintetica();

            origem.nome_rota = String(this.formNovaRota.controls["rota_nome"].value).toLocaleUpperCase();
            origem.name = "ORIGEM";
            origem.tipo_logradouro = String(this.formNovaRota.controls["rota_origem_logradouro"].value).toLocaleUpperCase();
            origem.endereco = String(this.formNovaRota.controls["rota_origem_endereco"].value).toLocaleUpperCase();
            origem.end_numero = String(this.formNovaRota.controls["rota_origem_numero"].value).toLocaleUpperCase();
            origem.bairro = String(this.formNovaRota.controls["rota_origem_bairro"].value).toLocaleUpperCase();
            origem.cidade = String(this.formNovaRota.controls["rota_origem_cidade"].value).toLocaleUpperCase();
            origem.oficial_gps_lat = latitude;
            origem.oficial_gps_lon = longitude;
            origem.objetivo_de_venda = 0;

            this.novaRotaAgrupada.push(origem);
          } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
            this.toastr.error("", "Endereço de origem inválido!");

            this.novaRotaOrigem = false;
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");

            this.novaRotaOrigem = false;
          }

          return resolver();
        });
      } else {
        /* Por Latitude/Longitude */

        let latitudeLongitude = {
          lat: latitude,
          lng: longitude,
        };

        geolocalizacao.geocode({
          "location": latitudeLongitude
        }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              let logradouro: string = "RUA";

              if (results[0].address_components[1].long_name.toUpperCase().indexOf("AVENIDA") !== -1) {
                logradouro = "AVENIDA"
              } else if (results[0].address_components[1].long_name.toUpperCase().indexOf("RODOVIA") !== -1) {
                logradouro = "RODOVIA"
              }

              let endereco: string = results[0].address_components[1].long_name.toUpperCase().replace(logradouro, "").trim();

              let numero: string = results[0].address_components[0].short_name;

              if (numero.toUpperCase().indexOf("UNNAMED ROAD") !== -1) {
                numero = "0";
              }

              let bairro: string = results[0].address_components[2].long_name.toUpperCase();

              let cidade: string = "";

              if (results[0].address_components.length > 3) {
                cidade = results[0].address_components[3].long_name.toUpperCase();
              } else {
                cidade = results[0].address_components[0].long_name.toUpperCase();
              }

              this.formNovaRota.controls["rota_origem_logradouro"].setValue(logradouro);
              this.formNovaRota.controls["rota_origem_endereco"].setValue(endereco);
              this.formNovaRota.controls["rota_origem_numero"].setValue(numero);
              this.formNovaRota.controls["rota_origem_bairro"].setValue(bairro);
              this.formNovaRota.controls["rota_origem_cidade"].setValue(cidade);

              this.modalNovaRota(content);

              this.toastr.success("", "Origem selecionada!");
            }
          }
        });
      }
    });

    return retorno;
  }

  onMouseOver(info: any, maps: any) {
    if (this.rotasNova) {
      if (maps.lastOpen != null) {
        maps.lastOpen.close();
      }

      maps.lastOpen = info;

      info.open();
    }
  }

  onMouseOut(maps: any) {
    if (this.rotasNova) {
      maps.lastOpen.close();
    }
  }

  onResponse(evento: any, rota: number) {
    try {
      if (evento.status == google.maps.GeocoderStatus.OK) {
        if (evento.routes[0] != null) {
          if (this.rotasNova) {
            let rotaOtimizada: RotaSintetica[] = [];

            if (!evento.request.optimizeWaypoints) {
              /* Salvar */

              this.novaRotaAgrupada.forEach((item) => {
                if (item.name != "ORIGEM" && item.name != "DESTINO") {
                  rotaOtimizada.push(item);
                }
              });

              this.rotasAgrupadas[0].value = rotaOtimizada;
            } else {
              /* Salvar (Otimizar) */

              evento.routes[0].waypoint_order.forEach((item) => {
                rotaOtimizada.push(this.novaRotaAgrupada[item + 1]);
              });

              this.rotasAgrupadas[0].value = rotaOtimizada;
            }
          }

          let distanciaTotal: number = 0;

          for (var i = 0; i < evento.routes[0].legs.length; i++) {
            if (i == 0 && this.rotasAgrupadas[rota].value[i] != null) {
              this.rotasAgrupadas[rota].value[i].distancia_rota = "0 m";
              this.rotasAgrupadas[rota].value[i].distancia = "0 m";
            }

            if (this.rotasAgrupadas[rota].value[i + 1] != null) {
              this.rotasAgrupadas[rota].value[i + 1].distancia_rota = "0 m";
              this.rotasAgrupadas[rota].value[i + 1].distancia = this.conversaoService.metrosParaKilometros(evento.routes[0].legs[i].distance.value);
            }

            distanciaTotal = distanciaTotal + evento.routes[0].legs[i].distance.value;
          }

          this.rotasAgrupadas[rota].distancia_rota = this.conversaoService.metrosParaKilometros(distanciaTotal);

          if (this.rotasNova && !this.novaRotaProcessando) {
            this.salvarRotas();

            this.novaRotaProcessando = true;
          }
        }
      } else if (evento.status == google.maps.GeocoderStatus.ZERO_RESULTS) {

      } else {
        this.toastr.error("", "Houve um erro, contate o suporte...");
      }

      this.atualizarChamadas();
      this.atualizarDistancia(rota);
    } catch(e) {
      this.toastr.error("", "Houve um erro, contate o suporte...");
    }
  }

  reposicionar(sem_rota: boolean, posicao_atual: number, rota_origem?: number) {
    let client_id: number = 0;
    let endereco: string = "";

    if (sem_rota) {
      /* Sem Rota */

      let rotaOrigem: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == this.clientesSemRota);

      if (rotaOrigem != -1) {
        client_id = this.rotasAgrupadas[rotaOrigem].value[posicao_atual].client_id;
        endereco = this.rotasAgrupadas[rotaOrigem].value[posicao_atual].endereco_completo;
      } else {
        return;
      }
    } else {
      /* Com Rota */

      if (this.rotasAgrupadas[rota_origem] != null && this.rotasAgrupadas[rota_origem].value[posicao_atual] != null) {
        client_id = this.rotasAgrupadas[rota_origem].value[posicao_atual].client_id;
        endereco = this.rotasAgrupadas[rota_origem].value[posicao_atual].endereco_completo;
      } else {
        return;
      }
    }

    let geolocalizacao = new google.maps.Geocoder();

    geolocalizacao.geocode({
      "address": endereco
    }, (results, status) => {
        this.atualizarChamadas();

        if (status == google.maps.GeocoderStatus.OK) {
          let latitude: number = results[0].geometry.location.lat();
          let longitude: number = results[0].geometry.location.lng();
          let precisao: string = results[0].geometry.location_type;

          let alterarGeolocalizacao: boolean = false;

          this.clienteService.updateGeolocalizacaoById(client_id, latitude, longitude, precisao).subscribe(
            alterarGeolocalizacoes => alterarGeolocalizacao = alterarGeolocalizacoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (alterarGeolocalizacao) {
                this.toastr.success("", "Reposicionamento realizado com sucesso!");

                this.atualizarRotas();
              }
            }
          );
        } else {
          this.toastr.error("", "Houve um erro, contate o suporte...");
        }
      }
    );
  }

  /* Rotas */

  adicionarMarcador(latitude: number, longitude: number, content: any) {
    if (this.novaRotaDefinindoDestino) {
      /* Destino */

      const buscarDestino = this.buscarDestino(false, latitude, longitude, content);

      buscarDestino.then(() => {

      });
    } else if (this.novaRotaDefinindoOrigem) {
      /* Origem */

      const buscarOrigem = this.buscarOrigem(false, latitude, longitude, content);

      buscarOrigem.then(() => {

      });
    }
  }

  alternarPonto(ponto: number, sem_rota: boolean, rota?: number) {
    if (sem_rota && this.rotasNova) {
      this.mapaClienteSemRotaPontoSelecionado = ponto;

      let id: string = "";

      if (ponto == 0) {
        id = this.mapaClienteSemRota[0].markerOptions.origin.id;

        this.mapaClienteSemRota[0].markerOptions.origin.icon.selecionado = !this.mapaClienteSemRota[0].markerOptions.origin.icon.selecionado;
      } else if (ponto == this.mapaClienteSemRota[0].markerOptions.waypoints.length + 1) {
        id = this.mapaClienteSemRota[0].markerOptions.destination.id;

        this.mapaClienteSemRota[0].markerOptions.destination.icon.selecionado = !this.mapaClienteSemRota[0].markerOptions.destination.icon.selecionado;
      } else {
        id = this.mapaClienteSemRota[0].markerOptions.waypoints[ponto - 1].id;

        this.mapaClienteSemRota[0].markerOptions.waypoints[ponto - 1].icon.selecionado = !this.mapaClienteSemRota[0].markerOptions.waypoints[ponto - 1].icon.selecionado;
      }

      let rota = this.rotas.find(rota => rota.id == id);

      if (rota != null) {
        let novaRota: number = this.novaRotaAgrupada.findIndex(novaRota => novaRota.id == id);

        if (novaRota != -1) {
          this.novaRotaAgrupada.splice(novaRota, 1);

          this.novaRotaAgrupada = [...this.novaRotaAgrupada];

          this.toastr.success("", "Cliente removido!");
        } else {
          let posicao: number = 0;

          if (this.novaRotaAgrupada.length > 0) {
            posicao = this.novaRotaAgrupada.length - 1;
          }

          this.novaRotaAgrupada.splice(posicao, 0, rota);

          this.novaRotaAgrupada = [...this.novaRotaAgrupada];

          this.toastr.success("", "Cliente adicionado!");
        }

        this.novaRotaTotalObjetivo = this.novaRotaAgrupada.reduce((sum, current) => sum + current.objetivo_de_venda, 0);
      }
    } else {
      this.mapaClienteComRotaSelecionado = rota != undefined ? rota : -1;
      this.mapaClienteComRotaPontoSelecionado = ponto;
    }
  }

  atualizarDestino(rota_destino: string) {
    let rotaDestino: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == rota_destino);

    if (rotaDestino != -1) {
      this.rotasSalvar = true;

      this.rotasAgrupadas[rotaDestino].alterada = true;

      this.atualizarFrequencia();

      this.definirRota(rotaDestino, false, false);
    }
  }

  atualizarOrigem(rota_origem: string) {
    let rotaOrigem: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == rota_origem);

    if (rotaOrigem != -1) {
      this.rotasSalvar = true;

      this.rotasAgrupadas[rotaOrigem].alterada = true;

      this.atualizarFrequencia();

      this.definirRota(rotaOrigem, false, false);
    }
  }

  atualizarRotas() {
    this.limparRotas();
    this.carregarDados(true);
  }

  definirPosicoesRotaDestino(rota_destino: string) {
    this.rotaSelecionada = 0;

    this.rotaPosicaoSelecionada = 0;

    this.rotaSelecionadaPosicoes = [];

    if (this.rotasAgrupadas.length > 0) {
      this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == rota_destino);

      if (this.rotasAgrupadas[this.rotaSelecionada].value != null) {
        this.rotaPosicaoSelecionada = 0;

        for (var i = 0; i < this.rotasAgrupadas[this.rotaSelecionada].value.length + 1; i++) {
          this.rotaSelecionadaPosicoes.push(i);
        }
      }
    }
  }

  definirPosicao(posicao: string) {
    this.rotaPosicaoSelecionada = parseInt(posicao);
  }

  definirRota(rota: number, inicial: boolean, otimizar: boolean) {
    let mapaRota = {
      name: "",
      origin: {
        lat: 0,
        lng: 0
      },
      destination: {
        lat: 0,
        lng: 0
      },
      visible: false,
      markerOptions: {
        origin: {},
        waypoints: [],
        destination: {}
      },
      renderOptions: {
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#000",
          strokeWeight: 5,
          strokeOpacity: 0.6
        }
      },
      travelMode: "DRIVING",
      optimizeWaypoints: false,
      waypoints: []
    };

    if (otimizar) {
      mapaRota.optimizeWaypoints = true;
    }

    if (this.rotasAgrupadas[rota] != null) {
      if (this.rotasAgrupadas[rota].value.length > 0) {
        let mapaPonto = null;
        let mapaInformacao = null;

        let origemLatitude: number = 0;
        let origemLongitude: number = 0;

        let destinoLatitude: number = 0;
        let destinoLongitude: number = 0;

        if (this.rotasAgrupadas[rota].key != this.clientesSemRota) {
          this.rotasAgrupadas[rota].cor = this.iconeCores[this.rotasAgrupadas[rota].icone - 1];
        }

        mapaRota.name = this.rotasAgrupadas[rota].key;

        origemLatitude = this.rotasAgrupadas[rota].value[0].oficial_gps_lat;
        origemLongitude = this.rotasAgrupadas[rota].value[0].oficial_gps_lon;

        mapaRota.origin.lat = origemLatitude;
        mapaRota.origin.lng = origemLongitude;

        destinoLatitude = this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].oficial_gps_lat;
        destinoLongitude = this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].oficial_gps_lon;

        mapaRota.destination.lat = destinoLatitude;
        mapaRota.destination.lng = destinoLongitude;

        mapaRota.visible = inicial ? this.visibilidadeInicial : true;

        /* Origem */

        let iconeOrigem: string = this.iconeOrigem + "sem_rota/sem_rota.png";

        if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
          /* S/ Rota */

          if (this.rotasAgrupadas[rota].value[0].relationship == "Ativo") {
            iconeOrigem = this.iconeOrigem + "sem_rota/sem_rota_ativo.png";
          } else if (this.rotasAgrupadas[rota].value[0].relationship == "Ativo batido") {
            iconeOrigem = this.iconeOrigem + "sem_rota/sem_rota_ativo_batido.png";
          } else if (this.rotasAgrupadas[rota].value[0].relationship == "Ativo Premium") {
            iconeOrigem = this.iconeOrigem + "sem_rota/sem_rota_ativo_premium.png";
          } else if (this.rotasAgrupadas[rota].value[0].relationship == "Inativo") {
            iconeOrigem = this.iconeOrigem + "sem_rota/sem_rota_inativo.png";
          } else if (this.rotasAgrupadas[rota].value[0].relationship == "Prospecção") {
            iconeOrigem = this.iconeOrigem + "sem_rota/sem_rota_prospeccao.png";
          }
        } else {
          iconeOrigem = this.iconeOrigem + this.conversaoService.zeroEsquerda(this.rotasAgrupadas[rota].icone.toString(), 2) + "/001.png";
        }

        mapaRota.markerOptions.origin = {
          id: this.rotasAgrupadas[rota].value[0].id,
          infoWindow: this.gerarInformacao(rota, 0),
          icon: {
            url: iconeOrigem,
            scaledSize: {
              width: 30,
              height: 45
            },
            selecionado: false
          }
        };

        /* Destino */

        let iconeDestino: string = this.iconeOrigem + "sem_rota/sem_rota.png";

        if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
          /* S/ Rota */

          if (this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].relationship == "Ativo") {
            iconeDestino = this.iconeOrigem + "sem_rota/sem_rota_ativo.png";
          } else if (this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].relationship == "Ativo batido") {
            iconeDestino = this.iconeOrigem + "sem_rota/sem_rota_ativo_batido.png";
          } else if (this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].relationship == "Ativo Premium") {
            iconeDestino = this.iconeOrigem + "sem_rota/sem_rota_ativo_premium.png";
          } else if (this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].relationship == "Inativo") {
            iconeDestino = this.iconeOrigem + "sem_rota/sem_rota_inativo.png";
          } else if (this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].relationship == "Prospecção") {
            iconeDestino = this.iconeOrigem + "sem_rota/sem_rota_prospeccao.png";
          }
        } else {
          iconeDestino = this.iconeOrigem + this.conversaoService.zeroEsquerda(this.rotasAgrupadas[rota].icone.toString(), 2) + "/" + this.conversaoService.zeroEsquerda(this.rotasAgrupadas[rota].value.length, 3) + ".png"
        }

        mapaRota.markerOptions.destination = {
          id: this.rotasAgrupadas[rota].value[this.rotasAgrupadas[rota].value.length - 1].id,
          infoWindow: this.gerarInformacao(rota, this.rotasAgrupadas[rota].value.length - 1),
          icon: {
            url: iconeDestino,
            scaledSize: {
              width: 30,
              height: 45
            },
            selecionado: false
          }
        };

        if (this.rotasAgrupadas[rota].key != this.clientesSemRota) {
          mapaRota.renderOptions.polylineOptions.strokeColor = this.iconeCores[this.rotasAgrupadas[rota].icone - 1];
        }

        /* Intermediários */

        for (var j = 0; j < this.rotasAgrupadas[rota].value.length; j++) {
          if (j > 0 && j < this.rotasAgrupadas[rota].value.length - 1) {
            let iconeIntermediario: string = this.iconeOrigem + "sem_rota/sem_rota.png";

            if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
              /* S/ Rota */

              if (this.rotasAgrupadas[rota].value[j].relationship == "Ativo") {
                iconeIntermediario = this.iconeOrigem + "sem_rota/sem_rota_ativo.png";
              } else if (this.rotasAgrupadas[rota].value[j].relationship == "Ativo batido") {
                iconeIntermediario = this.iconeOrigem + "sem_rota/sem_rota_ativo_batido.png";
              } else if (this.rotasAgrupadas[rota].value[j].relationship == "Ativo Premium") {
                iconeIntermediario = this.iconeOrigem + "sem_rota/sem_rota_ativo_premium.png";
              } else if (this.rotasAgrupadas[rota].value[j].relationship == "Inativo") {
                iconeIntermediario = this.iconeOrigem + "sem_rota/sem_rota_inativo.png";
              } else if (this.rotasAgrupadas[rota].value[j].relationship == "Prospecção") {
                iconeIntermediario = this.iconeOrigem + "sem_rota/sem_rota_prospeccao.png";
              }
            } else {
              iconeIntermediario = this.iconeOrigem + this.conversaoService.zeroEsquerda(this.rotasAgrupadas[rota].icone.toString(), 2) + "/" + this.conversaoService.zeroEsquerda((j + 1).toString(), 3) + ".png"
            }

            mapaInformacao = {
              id: this.rotasAgrupadas[rota].value[j].id,
              infoWindow: this.gerarInformacao(rota, j),
              icon: {
                url: iconeIntermediario,
                scaledSize: {
                  width: 30,
                  height: 45
                },
                selecionado: false
              }
            };

            mapaRota.markerOptions.waypoints.push(mapaInformacao);

            mapaPonto = {
              location: {
                lat: this.rotasAgrupadas[rota].value[j].oficial_gps_lat,
                lng: this.rotasAgrupadas[rota].value[j].oficial_gps_lon
              },
              stopover: true
            };

            mapaRota.waypoints.push(mapaPonto);
          }
        }

        this.rotasAgrupadas[rota].total_objetivo_de_venda = this.rotasAgrupadas[rota].value.reduce((sum, current) => sum + current.objetivo_de_venda, 0);
      } else {
        this.rotasAgrupadas[rota].distancia_rota = "0 m";
        this.rotasAgrupadas[rota].total_objetivo_de_venda = 0;
      }

      if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
        /* S/ Rota */

        if (!inicial) {
          this.mapaClienteSemRota = [];
        }

        this.mapaClienteSemRota.push(mapaRota);
      }

      if (!inicial) {
        this.mapaRotas[rota] = mapaRota;
      }
    }

    return mapaRota;
  }

  duplicarPonto() {
    if (this.duplicarRotaSelecionada != null) {
      if (this.rotasAgrupadas[this.rotaSelecionada] != null) {
        let evento: DndDropEvent = {
          event: null,
          data: this.duplicarRotaSelecionada,
          dropEffect: "copy",
          index: this.rotaPosicaoSelecionada,
          isExternal: false
        };

        /* Destino */

        this.onDrop(evento, this.rotasAgrupadas[this.rotaSelecionada].route_id_app, this.rotasAgrupadas[this.rotaSelecionada].key, this.rotasAgrupadas[this.rotaSelecionada].value);

        /* Atualização */

        this.expandirRecolherRota(this.rotaSelecionada, true);

        this.toastr.success("", "Duplicado p/ '" + this.rotasAgrupadas[this.rotaSelecionada].key + "' na posição " + this.rotaPosicaoSelecionada + "!");

        /* Rotas (Sintética) */

        this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key != this.clientesSemRota);

        this.definirPosicoesRotaDestino(this.rotasAgrupadas[this.rotaSelecionada].key);
      }
    }
  }

  fecharClientesSemRota() {
    if (this.rotasAgrupadas != null) {
      this.rotasAgrupadas.forEach((item, i) => {
        if (item.key == this.clientesSemRota) {
          /* S/ Rota */

          item.exibir = false;

          this.mapaRotas[i].visible = false;
        }
      });
    }
  }

  gerarInformacao(rota: number, ponto: number) {
    /* Bairro */

    let bairro = "";

    if (this.rotasAgrupadas[rota].value[ponto].bairro != null) {
      bairro = "<div>" + this.rotasAgrupadas[rota].value[ponto].bairro + "</div>";
    }

    /* Cidade */

    let cidade_estado_cep = "";

    if (this.rotasAgrupadas[rota].value[ponto].cidade != null) {
      cidade_estado_cep = "<div>" + this.rotasAgrupadas[rota].value[ponto].cidade;
    }

    /* Estado */

    if (this.rotasAgrupadas[rota].value[ponto].estado != null) {
      if (cidade_estado_cep != "") {
        cidade_estado_cep = cidade_estado_cep + " - ";
      } else {
        cidade_estado_cep = "<div>";
      }

      cidade_estado_cep = cidade_estado_cep + this.rotasAgrupadas[rota].value[ponto].estado;
    }

    /* Cep */

    if (this.rotasAgrupadas[rota].value[ponto].cep != null) {
      if (cidade_estado_cep != "") {
        cidade_estado_cep = cidade_estado_cep + " - ";
      } else {
        cidade_estado_cep = "<div>";
      }

      cidade_estado_cep = cidade_estado_cep + this.rotasAgrupadas[rota].value[ponto].cep;
    }

    if (cidade_estado_cep != "") {
      cidade_estado_cep = cidade_estado_cep + "</div>"
    }

    /* Telefone */

    let telefone = "";

    if (this.rotasAgrupadas[rota].value[ponto].phone != null) {
      telefone = "<div>" + this.rotasAgrupadas[rota].value[ponto].phone + "</div>";
    }

    return (
      "<div style='font-size: 12px; font-weight: bold;'>" +
        this.rotasAgrupadas[rota].value[ponto].name +
      "</div>" +
      "<div>" +
        this.rotasAgrupadas[rota].value[ponto].tipo_logradouro + " " + this.rotasAgrupadas[rota].value[ponto].endereco + ", " + this.rotasAgrupadas[rota].value[ponto].end_numero +
      "</div>" +
      bairro +
      cidade_estado_cep +
      telefone +
      "<br>" +
      "<div style='font-weight: bold; color: " + this.rotasAgrupadas[rota].cor + "'>" +
        this.rotasAgrupadas[rota].value[ponto].nome_rota +
      "</div>"
    );
  }

  limparRotas() {
    /* Carregamento */

    this.processando = 0;

    /* Maps */

    this.iconeCor = 0;

    this.mapaRotas = [];
    this.mapaRotasContador = 0;

    this.mapaClienteComRota = [];
    this.mapaClienteComRotaSelecionado = -1;
    this.mapaClienteComRotaPontoSelecionado = -1;

    this.mapaClienteSemRota = [];
    this.mapaClienteSemRotaPontoSelecionado = -1;

    /* Rotas (Sintética) */

    this.rotasAgrupadasOriginal = JSON.parse(JSON.stringify(this.rotasAgrupadas));

    this.rotasNova = false;
    this.rotasSalvar = false;
  }

  moverPonto(posicao_atual: number, sem_rota: boolean) {
    if (sem_rota && this.rotasAgrupadas[this.rotaSelecionada] != null) {
      let rotaOrigem: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == this.clientesSemRota);

      if (rotaOrigem != -1) {
        let evento: DndDropEvent = {
          event: null,
          data: this.rotasAgrupadas[rotaOrigem].value[posicao_atual],
          dropEffect: "move",
          index: this.rotaPosicaoSelecionada,
          isExternal: false
        };

        /* Destino */

        this.onDrop(evento, this.rotasAgrupadas[this.rotaSelecionada].route_id_app, this.rotasAgrupadas[this.rotaSelecionada].key, this.rotasAgrupadas[this.rotaSelecionada].value);

        /* Origem */

        this.onDragged(this.rotasAgrupadas[rotaOrigem].value[posicao_atual], this.rotasAgrupadas[rotaOrigem].value, "move");

        /* Atualização */

        this.expandirRecolherRota(this.rotaSelecionada, true);

        this.toastr.success("", "Enviado p/ '" + this.rotasAgrupadas[this.rotaSelecionada].key + "' na posição " + this.rotaPosicaoSelecionada + "!");

        /* Maps */

        this.mapaClienteSemRotaPontoSelecionado = -1;

        /* Rotas (Sintética) */

        this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key != this.clientesSemRota);

        this.definirPosicoesRotaDestino(this.rotasAgrupadas[this.rotaSelecionada].key);
      }
    } else {
      /* Maps */

      this.mapaClienteComRotaSelecionado = -1;
      this.mapaClienteComRotaPontoSelecionado = -1;
    }
  }

  processarRotas(reprocessar: boolean) {
    let delay: number = 50;

    if (reprocessar) {
      if (this.mapaRotasContador > 0 && this.mapaRotasContador % 5 != 0) { /* Requisições */
        delay = 200;
      }

      setTimeout(() => {
        if (this.mapaRotasContador < this.rotasAgrupadas.length) {
          this.mapaRotas.push(this.definirRota(this.mapaRotasContador, true, false));

          this.mapaRotasContador++;

          this.processando = Math.round((this.mapaRotasContador / this.rotasAgrupadas.length) * 100);

          this.processarRotas(true);
        } else {
          setTimeout(() => {
            this.processarRotas(false);

            this.atualizarChamadas();

            this.reabrirRotas();

            this.carregando = false;
          }, 300);
        }
      }, delay);
    }
  }

  reabrirRotas() {
    if (this.rotasAgrupadas != null && this.rotasAgrupadasOriginal != null) {
      this.rotasAgrupadasOriginal.forEach((item) => {
        if (item.exibir) {
          let rotaAnterior: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == item.key);

          if (rotaAnterior != -1) {
            this.expandirRecolherRota(rotaAnterior, true);
          }
        }
      });
    }
  }

  retornarIconeCor() {
    this.iconeCor++;

    if (this.iconeCor > 30) {
      this.iconeCor = 1;
    }

    return this.iconeCor;
  }

  selecionarPonto(rota: number, ponto: number) {
    if (this.rotasAgrupadas[rota] != null && this.rotasAgrupadas[rota].value[ponto] != null) {
      this.latitudeFoco = this.rotasAgrupadas[rota].value[ponto].oficial_gps_lat;
      this.longitudeFoco = this.rotasAgrupadas[rota].value[ponto].oficial_gps_lon;

      this.zoom = 12;

      if (this.rotasAgrupadas[rota].key == this.clientesSemRota) {
        /* S/ Rota */

        this.mapaClienteSemRotaPontoSelecionado = ponto;
      } else {
        let index = this.mapaClienteComRota.findIndex(mapaCliente => mapaCliente.name == this.rotasAgrupadas[rota].key);

        if (index != null) {
          this.mapaClienteComRotaSelecionado = index;
          this.mapaClienteComRotaPontoSelecionado = ponto;
        }
      }
    }
  }

  visualizarRotas(visualizar: boolean) {
    if (this.rotasAgrupadas != null) {
      if (visualizar) {
        this.rotasAgrupadas.forEach((item, i) => {
          if (!item.nova && item.key != this.clientesSemRota) {
            item.exibir = false;
            item.visualizar = visualizar;
          }
        });
      } else {
        this.rotasAgrupadas.forEach((item, i) => {
          if (!item.nova && item.key != this.clientesSemRota) {
            item.exibir = visualizar;
            item.visualizar = visualizar;

            /* Maps */

            this.mapaRotas[i].visible = visualizar;
          }
        });
      }
    }
  }

  /* Modal */

  /* Alterar (Rota) */

  iniciarAlterarRota(content: any, rota: number) {
    this.formAlterarRota.reset;

    this.enviadoAlterarRota = false;

    if (this.rotasAgrupadas[rota] != null) {
      let palavras: string[] = null;
      let diaVisitaInicial: Date = null;

      let route_id_app: number = this.rotasAgrupadas[rota].route_id_app;

      if (route_id_app > 0) {
        palavras = this.rotasAgrupadas[rota].key.split(" [");

        if (this.rotasAgrupadas[rota].dia_visita != null) {
          diaVisitaInicial = new Date(this.rotasAgrupadas[rota].dia_visita);
        }
      } else {
        palavras = this.novaRotaDados.name.split(" [");
        diaVisitaInicial = this.novaRotaDados.dia_visita;
      }

      if (palavras.length > 0) {
        this.formAlterarRota.controls["rota_nome"].setValue(palavras[0]);
      }

      if (diaVisitaInicial != null) {
        let diaVisitaFinal = new Date(diaVisitaInicial);

        this.formAlterarRota.controls["rota_dia_visita"].setValue({
          year: diaVisitaFinal.getFullYear(),
          month: diaVisitaFinal.getMonth() + 1,
          day: diaVisitaFinal.getDate()
        });
      } else {
        this.formAlterarRota.controls["rota_dia_visita"].setValue(this.campoDiaVisita);
      }

      this.modalAlterarRota(content, rota);
    }
  }

  modalAlterarRota(content: any, rota: number) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-alterar-rota",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        this.enviadoAlterarRota = true;

        if (this.formAlterarRota.valid) {
          /* Nome */

          let rotaNome: string = this.formAlterarRota.controls["rota_nome"].value.toString();

          /* Dia Visita */

          let diaVisita: Date = new Date();

          let rotaDiaVisitaInicial = this.formAlterarRota.controls["rota_dia_visita"].value;

          if (rotaDiaVisitaInicial != null) {
            let diaVisitaFinal: Date = new Date(rotaDiaVisitaInicial.year, rotaDiaVisitaInicial.month - 1, rotaDiaVisitaInicial.day, 0, 0, 0);

            diaVisita = new Date(this.datePipe.transform(diaVisitaFinal, "yyyy/MM/dd HH:mm:ss"));
          } else {
            diaVisita = null;
          }

          let route_id_app: number = this.rotasAgrupadas[rota].route_id_app;

          if (route_id_app > 0) {
            if (diaVisita != null) {
              diaVisita = new Date(this.datePipe.transform(diaVisita, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
            }

            let alterarRota: boolean = false;

            this.rotaService.updateNameDiaVisitaByRouteIdApp(route_id_app, rotaNome, diaVisita).subscribe(
              alterarRotas => alterarRota = alterarRotas,
              error => { console.log("Erro: " + error) },
              () => {
                if (alterarRota) {
                  this.toastr.success("", "Alteração realizada com sucesso!");

                  this.atualizarRotas();
                }
              }
            );
          } else {
            /* Nome */

            this.rotasAgrupadas[rota].key = rotaNome;

            /* Dia Visita */

            this.rotasAgrupadas[rota].dia_Visita = diaVisita;

            this.toastr.success("", "Alteração realizada com sucesso!");
          }

          /* Nova (Rota) */

          if (this.novaRotaDados != null) {
            this.novaRotaDados.name = rotaNome;
            this.novaRotaDados.dia_visita = diaVisita;
          }
        } else {
          if (this.formAlterarRota.controls["rota_dia_visita"].errors != null) {
            this.formAlterarRota.controls["rota_dia_visita"].setValue(this.campoDiaVisita);
          }

          this.modalAlterarRota(content, rota);
        }
      }
    }, () => {

    });
  }

  /* Duplicar (Cliente) */

  modalDuplicarCliente(content: any, posicao_atual: number, rota_origem: number) {
    /* Duplicar (Cliente) */

    this.duplicarRotaSelecionada = null;

    if (this.rotasAgrupadas[rota_origem] != null && this.rotasAgrupadas[rota_origem].value[posicao_atual] != null) {
      this.duplicarRotaSelecionada = this.rotasAgrupadas[rota_origem].value[posicao_atual];
    }

    if (this.duplicarRotaSelecionada != null) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-duplicar-cliente",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Duplicar") {
          let rotaDestino: RotaSintetica[] = this.rotasAgrupadas[this.rotaSelecionada].value;

          let cliente: RotaSintetica = rotaDestino.find(rotaDestino => rotaDestino.client_id == this.duplicarRotaSelecionada.client_id);

          if (cliente != null) {
            this.toastr.error("", "Cliente já existente na rota de destino...");

            this.modalDuplicarCliente(content, posicao_atual, rota_origem);
          } else {
            this.duplicarPonto();
          }
        } else {
          /* Rotas (Sintética) */

          this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key != this.clientesSemRota);

          this.rotaPosicaoSelecionada = 0;
        }
      }, () => {
        /* Rotas (Sintética) */

        this.rotaSelecionada = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key != this.clientesSemRota);

        this.rotaPosicaoSelecionada = 0;
      });
    }
  }

  /* Excluir (Rota) */

  modalExcluirRota(content: any, route_id_app: number) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-excluir-rota",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Sim") {
        let excluirRota: boolean = false;

        this.rotaService.updateIsActiveByRoute(route_id_app, false).subscribe(
          excluirRotas => excluirRota = excluirRotas,
          error => { console.log("Erro: " + error) },
          () => {
            if (excluirRota) {
              this.toastr.success("", "Rota excluída com sucesso!");

              this.atualizarRotas();
            }
          }
        );
      }
    }, () => {

    });
  }

  /* Iniciar (Rotas) */

  modalIniciarRotas(content: any, alterar_vendedor: boolean) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-iniciar-rotas",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Cancelar") {
        if (!alterar_vendedor) {
          this.router.navigate(["/pedidos"]);
        }
      } else if (acao == "Iniciar") {
        if (this.vendedorSelecionado != null) {
          this.carregarRotas();
        }
      }
    }, (reacao) => {
      if (reacao == 1) { // 1 = Esc
        if (!alterar_vendedor) {
          this.router.navigate(["/pedidos"]);
        }
      }
    });
  }

  /* Nova (Rota) */

  iniciarNovaRota(content: any) {
    this.formNovaRota.controls["rota_nome"].setValue("");
    this.formNovaRota.controls["rota_dia_visita"].setValue(this.campoDiaVisita);

    /* Origem */

    this.formNovaRota.controls["rota_origem_logradouro"].setValue("");
    this.formNovaRota.controls["rota_origem_endereco"].setValue("");
    this.formNovaRota.controls["rota_origem_numero"].setValue("");
    this.formNovaRota.controls["rota_origem_bairro"].setValue("");
    this.formNovaRota.controls["rota_origem_cidade"].setValue("");

    /* Destino */

    this.formNovaRota.controls["rota_destino_logradouro"].setValue("");
    this.formNovaRota.controls["rota_destino_endereco"].setValue("");
    this.formNovaRota.controls["rota_destino_numero"].setValue("");
    this.formNovaRota.controls["rota_destino_bairro"].setValue("");
    this.formNovaRota.controls["rota_destino_cidade"].setValue("");

    /* Drag and Drop */

    this.exibirDragAndDrop = false;

    /* Maps */

    this.mapaClienteComRotaSelecionado = -1;
    this.mapaClienteComRotaPontoSelecionado = -1;

    this.mapaClienteSemRotaPontoSelecionado = -1;

    /* Nova (Rota) */

    this.formNovaRota.reset;

    this.enviadoNovaRota = false;

    this.novaRotaDefinindoDestino = false;
    this.novaRotaDefinindoOrigem = false;
    this.novaRotaDestino = true;
    this.novaRotaExibir = true;
    this.novaRotaOrigem = true;
    this.novaRotaProcessando = false;
    this.novaRotaTotalObjetivo = 0;

    this.novaRotaDados = null;

    this.novaRotaAgrupada = [];

    this.fecharClientesSemRota();
    this.visualizarRotas(false);

    this.modalNovaRota(content);
  }

  modalNovaRota(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-nova-rota",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Iniciar") {
        this.enviadoNovaRota = true;

        if (this.formNovaRota.valid) {
          const buscarEnderecos = this.buscarEnderecos();

          buscarEnderecos.then(() => {
            if (this.novaRotaOrigem && this.novaRotaDestino) {
              /* Nova (Rota) */

              let novaRota = new RotaSintetica();

              novaRota.name = this.formNovaRota.controls["rota_nome"].value;

              let diaVisitaInicial: any = this.formNovaRota.controls["rota_dia_visita"].value;

              if (diaVisitaInicial != null) {
                let diaVisita: Date = new Date(diaVisitaInicial.year, diaVisitaInicial.month - 1, diaVisitaInicial.day, 0, 0, 0);

                novaRota.dia_visita = new Date(this.datePipe.transform(diaVisita, "yyyy/MM/dd HH:mm:ss"));
              } else {
                novaRota.dia_visita = null;
              }

              this.novaRotaDados = novaRota;

              this.novaRotaProcessando = false;

              /* Maps */

              let mapaRota = {
                name: "",
                origin: {
                  lat: 0,
                  lng: 0
                },
                destination: {
                  lat: 0,
                  lng: 0
                },
                visible: false,
                markerOptions: {
                  origin: {},
                  waypoints: [],
                  destination: {}
                },
                renderOptions: {
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#000",
                    strokeWeight: 5,
                    strokeOpacity: 0.6
                  }
                },
                travelMode: "DRIVING",
                optimizeWaypoints: false,
                waypoints: []
              };

              this.mapaRotas.unshift(mapaRota);

              /* Rotas (Sintética) */

              this.rotasAgrupadas.unshift({
                key: this.novaRotaDados.name,
                value: [],
                route_id_app: 0,
                alterada: false,
                exibir: false,
                visualizar: false,
                nova: true,
                cor: "#000",
                icone: this.retornarIconeCor(),
                distancia_rota: "0 m",
                total_objetivo_de_venda: 0,
                dia_visita: this.novaRotaDados.dia_visita
              });

              /* Sem Rota */

              let rotaAgrupada: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == this.clientesSemRota);

              if (rotaAgrupada != -1) {
                if (!this.rotasAgrupadas[rotaAgrupada].exibir) {
                  this.expandirRecolherRota(rotaAgrupada, true);
                }
              }

              /* Nova (Rota) */

              this.novaRotaDefinindoDestino = false;
              this.novaRotaDefinindoOrigem = false;

              /* Rotas (Sintética) */

              this.rotasNova = true;
              this.rotasSalvar = true;
            } else {
              this.modalNovaRota(content);
            }
          });
        } else {
          if (this.formNovaRota.controls["rota_dia_visita"].errors != null) {
            this.formNovaRota.controls["rota_dia_visita"].setValue(this.campoDiaVisita);
          }

          this.modalNovaRota(content);
        }
      } else if (acao == "Cancelar") {
        /* Drag and Drop */

        this.exibirDragAndDrop = true;

        /* Nova (Rota) */

        this.novaRotaDefinindoDestino = false;
        this.novaRotaDefinindoOrigem = false;

        this.visualizarRotas(true);
      }
    }, (reacao) => {
      if (reacao == 1 || reacao == "Fechar") { // 1 = Esc
        /* Drag and Drop */

        this.exibirDragAndDrop = true;

        /* Nova (Rota) */

        this.novaRotaDefinindoDestino = false;
        this.novaRotaDefinindoOrigem = false;

        this.visualizarRotas(true);
      }
    });
  }

  /* Reposicionar (Cliente) */

  modalReposicionarCliente(content: any, sem_rota: boolean, posicao_atual: number, rota_origem?: number) {
    this.reposicionarRotaSelecionada = null;

    if (sem_rota) {
      /* Sem Rota */

      let rotaOrigem: number = this.rotasAgrupadas.findIndex(rotaAgrupada => rotaAgrupada.key == this.clientesSemRota);

      if (rotaOrigem != -1) {
        this.reposicionarRotaSelecionada = this.rotasAgrupadas[rotaOrigem].value[posicao_atual];
      }
    } else {
      /* Com Rota */

      if (rota_origem != undefined) {
        if (this.rotasAgrupadas[rota_origem] != null && this.rotasAgrupadas[rota_origem].value[posicao_atual] != null) {
          this.reposicionarRotaSelecionada = this.rotasAgrupadas[rota_origem].value[posicao_atual];
        }
      }
    }

    if (this.reposicionarRotaSelecionada != null) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-reposicionar-cliente",
        size: "lg",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Automatica") {
          this.reposicionar(sem_rota, posicao_atual, rota_origem);
        } else if (acao == "Manual") {
          this.enviadoReposicionarClienteManual = false;

          this.formReposicionarClienteManual.reset();

          this.modalReposicionarClienteManual(this.reposicionarClienteManual);
        }
      }, () => {

      });
    }
  }

  /* Reposicionar (Cliente) - Manual */

  modalReposicionarClienteManual(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-reposicionar-cliente-manual",
      size: "lg",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Salvar") {
        this.enviadoReposicionarClienteManual = true;

        if (this.formReposicionarClienteManual.valid) {
          if (this.reposicionarRotaSelecionada != null) {
            let latitude: number = this.formReposicionarClienteManual.controls["latitude"].value;
            let longitude: number = this.formReposicionarClienteManual.controls["longitude"].value;
            let precisao: string = "ROOFTOP";

            let alterarGeolocalizacao: boolean = false;

            this.clienteService.updateGeolocalizacaoById(this.reposicionarRotaSelecionada.client_id, latitude, longitude, precisao).subscribe(
              alterarGeolocalizacoes => alterarGeolocalizacao = alterarGeolocalizacoes,
              error => { console.log("Erro: " + error) },
              () => {
                if (alterarGeolocalizacao) {
                  this.toastr.success("", "Reposicionamento realizado com sucesso!");

                  this.atualizarRotas();
                }
              }
            );
          }
        } else {
          this.modalReposicionarClienteManual(content);
        }
      }
    }, () => {

    });
  }

  /* Vendedor(a) */

  alterarVendedor() {
    this.limparRotas();
    this.carregarDados(false);
  }

  selecionarVendedor(id_vendedor: string) {
    this.vendedorSelecionado = this.vendedores.find(vendedores => vendedores.id == parseInt(id_vendedor));
  }
}
