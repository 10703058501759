export class CallCenterOportunidadeFiltrar {
  public cidade: string;
  public classificacao: string;
  public cliente: string;
  public cnpj_cpf: string;
  public codigo: string;
  public nro_inicial_sem_compra: number;
  public nro_final_sem_compra: number;
  public nro_inicial_sem_visita: number;
  public nro_final_sem_visita: number;
  public valor_inicial_realizado_ano: number;
  public valor_final_realizado_ano: number;
  public valor_inicial_realizado_mes: number;
  public valor_final_realizado_mes: number;
  public segmento: string;
  public tags: string[] = [];
  public vendedor: string;
  public id_produto: number;

  constructor() { }
}
